import React, { Component } from 'react';

//Import Component
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import PrivacyFAQ from "../components/PrivacyFAQ";
import PrivacyBanner from "../components/banner/PrivacyBanner";

class PrivacyPolicy extends Component {
  render() {
    return (
        <React.Fragment>
            <NavBar pageName="privacy-policy" />
            <PrivacyBanner />
            <PrivacyFAQ />
            <Footer />
        </React.Fragment>
    );
  }
}

export default PrivacyPolicy;
