import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from 'react-router-dom';

class PrivacyBanner extends Component {
    render() {
        return (
            <React.Fragment>
                <div id="home" className="hompage-slides-wrapper">
                    <div className="single-slider-item slide-bg-1">
                        <div className="diplay-table">
                            <div className="display-table-cell">
                                <VisibilitySensor>
                                    {({ isVisible }) => (
                                    <div className="container">
                                        <div className="row justify-content-md-center">
                                            <div className="col-lg-7">
                                                <span
                                                    className={
                                                        isVisible
                                                            ? "hero-text animated fadeInDown slow opacityOne"
                                                            : "opacityZero"
                                                    }
                                                >
                                                    {this.props.TopTitle}
                                                </span>

                                                <h1
                                                    className={
                                                        isVisible
                                                            ? "animated fadeInDown slow opacityOne"
                                                            : "opacityZero"
                                                    }
                                                >
                                                    {this.props.Title}
                                                </h1>
                                                <p
                                                    className={
                                                        isVisible
                                                            ? "animated fadeInDown slow opacityOne"
                                                            : "opacityZero"
                                                    }
                                                >
                                                    {this.props.ContentOne}
                                                </p>
                                                <p
                                                    className={
                                                        isVisible
                                                            ? "animated fadeInDown slow opacityOne"
                                                            : "opacityZero"
                                                    }
                                                >
                                                    {this.props.ContentTwo}
                                                </p>
                                                <p
                                                    className={
                                                        isVisible
                                                            ? "animated fadeInDown slow opacityOne"
                                                            : "opacityZero"
                                                    }
                                                >
                                                    {this.props.ContentThree}
                                                </p>
                                                <p
                                                    className={
                                                        isVisible
                                                            ? "animated fadeInDown slow opacityOne"
                                                            : "opacityZero"
                                                    }
                                                >
                                                    {this.props.ContentFour}
                                                </p>
                                                {/* <div className="center-wrap">
                                                    <div className="center-wrap">
                                                        <Link to={this.props.BtnLink} className="btn-a">
                                                            <div className="button">
                                                                {this.props.BtnName}
                                                                <Icofont icon="icofont-long-arrow-right" />
                                                            <div className="mask" /></div>
                                                        </Link>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                </VisibilitySensor>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
//Props Types
PrivacyBanner.propTypes = {
    TopTitle: PropTypes.string,
    Title: PropTypes.string,
    Content: PropTypes.string,
    BtnLink: PropTypes.string,
    BtnName: PropTypes.string,
};

//Default Props
PrivacyBanner.defaultProps = {
    TopTitle: "",
    Title: "Privacy Policy",
    ContentOne: "N9th Degree Designs builds Ad Supported apps. This SERVICE is provided by N9th Degree Designs at no cost and is intended for use as is.",
    ContentTwo: "This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.",
    ContentThree: "If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.",
    ContentFour: "The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Daily Horoscopes 2019 unless otherwise defined in this Privacy Policy.",


    BtnLink:  "/#",
    BtnName: "get started",
};

export default PrivacyBanner;
